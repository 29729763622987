body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* * {
  margin: 0px;
  padding: 0px;
}

.outer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}



.emp-main-div {

  display: "flex";
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

}

.menu-div {

  width: 20vw;
  height: 90vh;
  background-color: gray;
  margin-top: 10vh;
}

.form-container {

  width: 80vw;
  height: 90vh;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh,
}

.form-div {

  width: " 50%";
  background-color: white;
  height: 100%;
  margin-right: 28%
}

.emp-div-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.emp-input-flex {
  display: "flex";
  flex-direction: column;

}

.emp-input {
  padding: "5%";
  border-radius: 5px;
  outline: none;
  background-color: white,
} */
